export default {
	以下是有关我们网站的一些注意事项: 'Dưới đây là một số lưu ý về trang web của chúng tôi',
	关于在线: "Về ATM trực tuyến",
	在线分期贷款平台: "ATM online là một nền tảng cho vay trả góp trực tuyến được thiết kế đặc biệt dành cho giới trẻ! Chúng tôi cho phép bạn vay tiền trả góp và trả góp trực tuyến bất cứ lúc nào, bằng cách có thể trả góp trong thời gian linh hoạt lên đến 36 tháng, đăng ký qua điện thoại và quy trình hoạt động đơn giản và rõ ràng. Bạn có thể dễ dàng theo dõi tiến độ trả góp của khoản vay. Giảm bớt gánh nặng cuộc sống và tạm thời giảm áp lực tài chính của bạn.Hạn mức vay cao: Từ 40.000.000 đồng trở lên, lên đến 1.000.000.000 đồng.Lãi suất tháng thấp: Lãi suất tháng chỉ từ 0,8%.Yêu cầu thấp: Chỉ cần có chứng minh nhân dân là có thể vay, không cần tài sản thế chấp.Vay nhanh chóng: Chỉ mất 30 phút để nhận được khoản vay.Xin lưu ý rằng đây chỉ là một bản dịch tự động và có thể không hoàn toàn chính xác.",
	'答：首先，您需要在APP上进行实名认证，实名认证后，即可申请贷款。目前，由于疫情情况，只要年满18岁即可申请贷款！': "Trả lời: Đầu tiên, bạn cần thực hiện xác thực danh tính trên ứng dụng, sau khi xác thực danh tính, bạn có thể đăng ký vay tiền. Hiện tại, do tình hình dịch bệnh, chỉ cần đủ 18 tuổi là có thể đăng ký vay tiền!",
	'答：从账户注册、实名认证、贷款审核和最终贷款审核，最快可在一小时内完成。同时，请保持电话保持正常状态，当您等待贷款审核时，工作人员会打电话给您进行核实。': "Trả lời: Từ đăng ký tài khoản, xác thực danh tính, kiểm tra và xác nhận vay tiền, quá trình nhanh nhất có thể hoàn thành trong một giờ. Đồng thời, vui lòng giữ điện thoại ở trạng thái hoạt động bình thường, khi bạn đang chờ kiểm tra vay tiền, nhân viên sẽ gọi điện để xác minh thông tin với bạn.",
	'答：您可以选择查看[个人中心]-[贷款申请]中的贷款结果。批准时间通常在工作时间的一小时内完成。': "Trả lời: Bạn có thể chọn xem kết quả vay tiền trong [Trung tâm cá nhân] - [Đơn vay]. Thời gian phê duyệt thường được hoàn thành trong một giờ làm việc.",
	'答：一旦提交贷款申请，一般无法取消，因此在申请前应仔细考虑。如果贷款登记获得批准，合同将生效。即使不再需要贷款，利息也会计算。': "Trả lời: Một khi đã nộp đơn vay, thì thông thường không thể hủy bỏ, do đó trước khi nộp đơn, bạn nên cân nhắc kỹ. Nếu đăng ký vay được chấp thuận, hợp đồng sẽ có hiệu lực. Ngay cả khi không còn cần vay nữa, lãi suất vẫn được tính toán.",
	'答：贷款成功后，还款方式只有一种方式。每月 10 日，相应金额将自动从您的银行卡中扣除。请在每月10日之前将银行卡中的余额保持充足。如果余额不足以偿还债务，您的信用信誉将受到影响。信用信誉绑定银行机构，这将影响您购车贷款和房屋抵押贷款的登记，以后的信用卡也会受到影响。': "Trả lời: Sau khi vay thành công, chỉ có một phương thức thanh toán. Vào ngày 10 hàng tháng, số tiền tương ứng sẽ tự động được trừ từ thẻ ngân hàng của bạn. Vui lòng đảm bảo có đủ số dư trong thẻ ngân hàng của bạn trước ngày 10 hàng tháng. Nếu số dư không đủ để trả nợ, uy tín tín dụng của bạn sẽ bị ảnh hưởng. Uy tín tín dụng liên kết với các tổ chức ngân hàng, điều này sẽ ảnh hưởng đến việc đăng ký vay mua ô tô và vay thế chấp nhà, cũng như ảnh hưởng đến thẻ tín dụng trong tương lai.",
	'上午9：00至21：00回复：客服中心可在[客户服务]栏目中在线联系。在线客户服务的工作时间为：上午 09：00 至下午 21：00': "Trả lời: Trung tâm dịch vụ khách hàng có thể được liên hệ trực tuyến trong mục [Dịch vụ khách hàng]. Thời gian làm việc của dịch vụ khách hàng trực tuyến là từ 9:00 sáng đến 21:00 tối.",
	'答：暂时没有提供“帐户取消”服务，您填写的个人信息都严格保密，只用于贷款申请。': "Trả lời: Hiện tại chưa cung cấp dịch vụ “hủy tài khoản” tạm thời, thông tin cá nhân mà bạn cung cấp sẽ được bảo mật chặt chẽ và chỉ sử dụng cho mục đích đăng ký vay.",
	旧密码: "Mật khẩu cũ ",
	请输入旧密码: "Vui lòng nhập mật khẩu cũ ",
	新密码: "Mật khẩu mới ",
	请输入新密码: "Vui lòng nhập mật khẩu mới ",
	确定密码: "Xác nhận mật khẩu ",
	请重新输入新密码: "Vui lòng nhập lại mật khẩu mới ",
	确认: "Xác nhận ",
	智慧信贷: "Tín dụng thông minh ",
	轻松贷款: "Vay tiền dễ dàng ",
	预计贷款金额: "Số tiền vay dự kiến ",
	越南盾: "VND",
	月利率: "Lãi suất hàng tháng ",
	
	恭喜: "Chúc mừng",
	成功借钱: "Vay thành công",
	预计贷款金额: "Số tiền vay dự kiến",
	韩元: "Won Hàn Quốc",
	预计贷款金额: "Số tiền vay dự kiến",
	现在申请: "Nộp Đơn",
	最高贷款额达: "Tối đa số tiền vay lên đến",
	'10分钟到钱，利率低每月仅0.8%，手续快捷，无需房产抵': "10 phút có tiền, lãi suất thấp chỉ 0.9% hàng tháng, thủ tục nhanh chóng, không cần tài sản đảm bảo.",
	登录: "Đăng nhập",
	登记: "Đăng ký",
	请输入您的手机号码: "Vui lòng nhập số điện thoại của bạn",
	请输入密码: "Vui lòng nhập mật khẩu",
	请输入您的邀请码: "Vui lòng nhập mã mời của bạn",
	登录: "Đăng nhập",
	修改登录密码: "Thay đổi mật khẩu đăng nhập",
	关于我们: "Về chúng tôi",
	版本: "Phiên bản",
	确认: "Xác nhận",
	改写: "Sửa đổi",
	账户余额: "Số dư tài khoản",
	立即提款: "Rút tiền ngay",
	我的银行卡: "Thẻ ngân hàng của tôi",
	无需绑定银行卡: "Không cần liên kết thẻ ngân hàng",
	资金账户安全由银行保障: "An toàn tài khoản tiền được bảo đảm bởi ngân hàng",
	提现状态: "Trạng thái rút tiền",
	提现时间: "Thời gian rút tiền",
	提现金额: "Số tiền rút",
	没有: "Không có",
	有: "Có",
	账户名称: "Tên tài khoản",
	请输入持卡人姓名: "Vui lòng nhập tên chủ thẻ",
	身份证: "CMND/CCCD",
	请输入身份证号码: "Vui lòng nhập số chứng minh nhân dân",
	存款银行: "Tên Ngân Hàng",
	银行名: "Tên ngân hàng",
	银行卡号: "Số thẻ ngân hàng",
	请输入银行卡号: "Vui lòng nhập số thẻ ngân hàng",
	发送确认信息: "Gửi thông tin xác nhận",
	教育: "Giáo dục",
	请选择您的最高学历: "Vui lòng chọn trình độ học vấn cao nhất của bạn",
	月收入: "Thu nhập hàng tháng",
	个人收入: "Thu nhập cá nhân",
	贷款用途: "Mục đích vay",
	请选择贷款用途: "Vui lòng chọn mục đích vay",
	有车或无车: "Có xe hay không",
	用户名: "Tên người dùng",
	请填写用户名: "Vui lòng điền tên người dùng",
	身份证号码: "CMND/CCCD",
	请填写身份证号码: "Vui lòng điền số chứng minh nhân dân",
	身份证照片上传: "Tải lên ảnh chứng minh nhân dân",
	上传身份证正面照片: "Tải lên ảnh mặt trước chứng minh nhân dân",
	上传身份证反面照片: "Tải lên ảnh mặt sau chứng minh nhân dân",
	客服服务: "Quản lý dịch vụ khách hàng",
	您的专属Line客服经理: "Quản lý dịch vụ khách hàng Line riêng của bạn",
	服务时间: "Thời gian dịch vụ khách hàng: Từ 8:00 sáng đến 20:00 tối hàng ngày",
	点击联系: "Nhấp để liên hệ",
	手机号码: "Số điện thoại di động",
	请输入手机号码: "Vui lòng nhập số điện thoại",
	钱包余额: "Số dư ví",
	已验证: "Đã xác minh",
	我的贷款: "Khoản vay của tôi",
	我的债务偿还: "Trả nợ của tôi",
	在线客服服务: "Hỗ trợ trực tuyến",
	常见问题: "Câu hỏi thường gặp",
	贷款协议: "HỢP ĐỒNG VAY VỐN",
	完全的: "Hoàn toàn",
	准确性: "Độ chính xác",
	为了保护您的资金安全: "Để bảo vệ an toàn tài chính của bạn, vui lòng điền thông tin sau đúng sự thật",
	个人信息: "Thông tin cá nhân",
	信息: "Thông tin",
	银行卡: "Thẻ ngân hàng",
	已验证电话: "Số điện thoại đã xác minh",
	手写签名: "Chữ ký bằng tay",
	
	点击查看贷款协议: "Nhấp để xem hợp đồng vay",
	我已仔细阅读贷款合同: "Tôi đã đọc kỹ hợp đồng vay và kiểm tra kỹ thông tin vay. Sau khi nhấp vào nút “Xác nhận” để đăng ký thanh toán, hợp đồng vay sẽ có hiệu lực. Nếu tôi vi phạm hợp đồng vì lý do cá nhân, tôi sẵn sàng chịu trách nhiệm trước mọi hậu quả pháp lý.",
	月利率: "Lãi suất hàng tháng",
	贷款期限: "Thời hạn vay",
	"贷款期限：":"Thời hạn vay:",
	贷款: "Vay",
	电话号码: "Số điện thoại",
	借款人姓名: "Tên người vay",
	贷款信息确认: "Xác nhận thông tin vay",
	传单: "Tờ rơi",
	'您的贷款申请已成功，请前往钱包提现': "Yêu cầu vay của bạn đã thành công, vui lòng đến ví để rút tiền",
	贷款详情: "Chi tiết vay",
	数字顺序: "Thứ tự số",
	序列号: "Số thứ tự",
	贷款进度: "Tiến độ vay",
	'债务偿还期限 （月）': 'Thời hạn trả nợ （tháng）',
	提交完成: "Hoàn thành việc gửi",
	账户成功: "Tài khoản thành công",
	银行账户: "Tài khoản ngân hàng",
	卡号: "Số thẻ",
	分期付款: "Trả góp",
	付款说明: "Hướng dẫn thanh toán",
	审核成功: "Phê duyệt thành công",
	贷款合同代码: "Mã hợp đồng vay",
	
	申请期间: "Thời gian đăng ký",
	查看合同: "Xem hợp đồng",
	还债时间: "Thời gian trả nợ",
	偿还债务金额: "Số tiền trả nợ",
	支付: "Thanh toán",
	银行: "Ngân hàng",
	您目前没有任何贷款订单: "Hiện tại bạn không có đơn vay nào",
	'1.甲方（贷款人）：': '1. Bên A (người cho vay):',
	'公司：': "Công ty:",
	'税号：': 'Mã số thuế:',
	'代表人：': 'Người đại diện: ',
	'职位：': 'Chức vụ:',
	'2.乙方（借款人）：': '2. Bên B(người vay): ',
	'全名（先生/女士）：': 'Họ và tên (Ông/Bà):',
	'电话号码：': 'Số điện thoại: ',
	'ID/CCCD号码：': 'Số ID/CCCD:',
	'借款日期：': 'Ngày vay:',
	'贷款币种：': 'Loại tiền vay: ',
	'贷款金额：': 'Số tiền vay:',
	'贷款利率：': 'Lãi suất được tính theo tổng số tiền vay ',
	
	'每月付款：': 'Thanh toán hàng tháng: ',
	'每月还款日期：': 'Ngày trả nợ hàng tháng: ',
	'双方同意甲方按以下条款从甲方资金中向乙方贷款：': 'Hai bên đồng ý Bên A cho vay Bên B từ nguồn vốn của Bên A theo các điều khoản sau: ',
	'第一条：甲方的权利和义务': 'Điều 1: Quyền và nghĩa vụ của Bên A',
	'- 具有甲方《运作规则》规定的权利和义务': '- Có quyền và nghĩa vụ theo Quy định vận hành của Bên A',
	'- 要求乙方履行承诺的义务。': '- Yêu cầu Bên B thực hiện cam kết.',
	'- 发现乙方提供虚假信息、违反合同的，停止贷款、终止贷款、到期前收回债务。': '- Phát hiện thông tin giả mạo hoặc vi phạm hợp đồng của Bên B, Bên A có quyền dừng cho vay, chấm dứt hợp đồng và thu hồi nợ trước hạn.',
	'- 按照本合同约定的时间表向乙方交付贷款。': '-Giao tiền vay cho Bên B theo lịch trình được quy định trong hợp đồng này',
	'- 每月收取贷款利息，如果乙方无法在付款期限内偿还债务，则收取逾期债务利息。': '- Thu lãi suất vay hàng tháng, nếu Bên B không thể trả nợ trong thời hạn thanh toán, sẽ thu lãi suất nợ quá hạn.',
	'第二条：贷款用途': 'Điều 2: Mục đích vay tiền',
	'- 贷款用途正确，严格遵守贷款合同约定的其他内容': '- Mục đích vay tiền đúng, tuân thủ nghiêm ngặt các nội dung khác được quy định trong hợp đồng vay',
	'- 按时足额偿还甲方的所有债务（本金和利息）': '-Trả đủ và đúng hạn tất cả các nợ của Bên A(gốc và lãi)',
	'- 因未能遵守本合同项下的承诺而承担法律责任。': '- Chịu trách nhiệm pháp lý do không thực hiện cam kết trong hợp đồng này.',
	'第三条：贷款利率及违约责任': 'Điều 3: Lãi suất vay và trách nhiệm vi phạm hợp đồng',
	'- 利息按贷款总额计算，按照贷款利率乘以贷款期限。': '-Lãi suất được tính theo tổng số tiền vay, theo lãi suất vay nhân với thời hạn vay.',
	'- 贷款期限自乙方收到贷款之日起至乙方全额支付本金和利息（如有逾期利息，包括逾期利息）之日止。': 'Thời hạn vay bắt đầu từ ngày Bên B nhận được khoản vay cho đến ngày Bên B thanh toán đầy đủ gốc và lãi(bao gồm cả lãi suất quá hạn).',
	'- 一旦客户确认贷款并向支付系统提交申请，即表示客户同意合同中规定的全部条款。': '- Khi khách hàng xác nhận khoản vay và gửi đơn đăng ký vào hệ thống thanh toán, đồng nghĩa với việc khách hàng đồng ý với tất cả các điều khoản được quy định trong hợp đồng.',
	'- 贷款合同正式生效，未偿还利率按月分期计算。': 'Hợp đồng vay có hiệu lực chính thức, lãi suất chưa thanh toán được tính theo từng kỳ trả góp hàng tháng.',
	'- 如果您不遵守所签订合同的条款，单方面解除合同将承担以下费用：': 'Nếu bạn không tuân thủ các điều khoản trong hợp đồng đã ký,việc chấm dứt hợp đồng một phía sẽ phải chịu các chi phí sau: ',
	'a.申请费：':'a. Phí đăng ký:', 
	'b.快速申请费：': 'b.Phí đăng ký nhanh: ',
	'c.合同补偿费为贷款价值的：':'c. Phí bồi thường hợp đồng là giá trị khoản vay', 
	'd.提前还款：提前还款时按余额的3%收取提前还款费。': 'Thanh toán trước: Khi thanh toán trước,phí thanh toán trước sẽ được tính 3 % trên số dư.',
	'第四条：争议的解决': 'Điều 4: Giải quyết tranh chấp',
	'- 这些合同纠纷将由双方本着平等互利的精神协商解决。':'- Các tranh chấp hợp đồng này sẽ được giải quyết thông qua đàm phán giữa hai bên với tinh thần bình đẳng và cùng có lợi.', 
	'- 如果争议不能通过协商解决，双方将向有管辖权的法院提起诉讼解决。': 'Nếu tranh chấp không thể được giải quyết thông qua đàm phán,hai bên sẽ đệ đơn tới tòa án có thẩm quyền để giải quyết.',
	'第五条：合同的效力': 'Điều 5: Hiệu lực của hợp đồng',
	'- 合同自签订之日起生效，至义务履行完毕时终止。':'- Hợp đồng có hiệu lực từ ngày ký kết và kết thúc khi nghĩa vụ được thực hiện đầy đủ.', 
	'越南ATM在线有限公司': 'Công ty TNHH ATM Trực tuyến Việt Nam',
	'地址：':'Địa chỉ:', 
	'胡志明市平盛区第 25 区奠边府 561 A 珍珠广场大厦 8 楼 02 号办公室': 'Tầng 8,Tòa nhà Trung tâm Pearl Plaza,561 A Điện Biên Phủ,Phường 25,Quận Bình Thạnh,Thành phố Hồ Chí Minh',
	'签约地址：':' Ngày ký kết hợp đồng:',
	'胡志明市， 2023 年 7 月， 22 年': 'Thành phố Hồ Chí Minh,tháng 7 năm 2023,năm 22',
	'（签名、 全名和印章）': '(Chữ ký, Họ tên đầy đủ và Dấu)',
	'合同编号':'số hợp đồng.',
	
	'信贷机构法':'Căn cứ vào Thông tư số 39/2016/TT-NHNN ngày 30/12/2016 của Luật Tổ chức tín dụng quy định về hoạt động hàng ngày của tổ chức tín dụng và chi nhánh ngân hàng nước ngoài đối với khách hàng.',
	'月':'tháng',
	
	注册失败:"Đăng ký thất bại", 
	用户已存在:"Người dùng đã tồn tại", 
	邀请码不存在:"Mã mời không tồn tại", 
	注册成功:"Đăng ký thành công", 
	用户不存在:"Người dùng không tồn tại", 
	密码错误:"Sai mật khẩu", 
	我的资料:"Thông tin của tôi", 
	工作信息:"Thông tin công việc", 
	验证电话:"Xác minh số điện thoại", 
	未完成:"Chưa hoàn thành", 
	已完成:"Đã hoàn thành", 
	非法格式:"Định dạng không hợp lệ", 
	无法修改:"Không thể chỉnh sửa", 
	信息不能为空:"Thông tin không được để trống", 
	成功:"Thành công", 
	取消:"Hủy bỏ", 
	装修:"Trang trí", 
	购物:"Mua sắm", 
	其他:"Khác", 
	是否有贷款:"Có hoặc không có nhà đất", 
	未分配客服:"Chưa được phân công nhân viên chăm sóc khách hàng", 
	退出:"Thoát", 
	退出成功:"Thoát thành công",
	登录成功:"Đăng nhập thành công", 
	请在我的资料完成个人信息:"Vui lòng hoàn thành thông tin cá nhân trong phần Thông tin của tôi", 
	请在我的资料填写工作信息:"Vui lòng điền thông tin công việc trong phần Thông tin của tôi", 
	请在我的资料填写银行卡填写:"Vui lòng điền thông tin thẻ ngân hàng trong phần Thông tin của tôi", 
	请在我的资料验证电话:"Vui lòng xác minh số điện thoại trong phần Thông tin của tôi", 
	请在我的资料验证签名:"Vui lòng xác minh chữ ký trong phần Thông tin của tôi", 
	等待审核:"Đang chờ xét duyệt", 
	已有贷款订单:"Đã có đơn vay", 
	审核中:"Đang xét duyệt", 
	审核通过:"Đã xét duyệt", 
	拒绝申请:"Từ chối đơn", 
	还款状态:"Trạng thái thanh toán", 
	未还款:"Chưa thanh toán", 
	
	初验通过:"Đã thông qua kiểm tra ban đầu", 
	核验资料:"Xác minh thông tin", 
	审核通过:"Đã xét duyệt", 
	等待下款:"Đang chờ nhận tiền", 
	已下款:"Xét duyệt thành công", 
	拒绝申请:"Từ chối đơn", 
	提现金额不足:"Số tiền rút không đủ", 
	已有提现订单:"Đã có đơn rút tiền",
	重写:"Viết lại",
	两次密码不一致:"Hai lần mật khẩu không phù hợp",
	修改成功:"Sửa đổi thành công",
	

	没有借款申请:"Không có yêu cầu vay tiền",
	身份证号码错误:"Số ID sai",
	手机号码不正确:"Số điện thoại không đúng",
	提现密码请联系在线客服获取:"Xin vui lòng liên hệ với dịch vụ khách hàng trực tuyến để lấy mật khẩu.",
	提现密码:"Nhập mật khẩu",
	提现密码错误:"Rút tiền mật khẩu sai",
	"债务偿还期限（月）":"Thời hạn trả nợ (tháng)",
	申请提现:"Rút tiền ngay",
	备注:"Lý do",
	提现失败:"Rút tiền thất bại",
	请重新登录:"Vui lòng đăng nhập lại",
}