import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: ()=>import('../views/login.vue')
  }, 
  {
    path: '/index',
    name: 'index',
    component: ()=>import('../views/index.vue')
  }, 
  {
    path: '/bank',
    name: 'bank',
    component: ()=>import('../views/bank.vue')
  }, 
  {
    path: '/dingdan',
    name: 'dingdan',
    component: ()=>import('../views/dingdan.vue')
  }, 
  {
    path: '/idcard',
    name: 'idcard',
    component: ()=>import('../views/idcard.vue')
  }, 
  {
    path: '/phone',
    name: 'phone',
    component: ()=>import('../views/phone.vue')
  }, 
  {
    path: '/form1',
    name: 'form1',
    component: ()=>import('../views/form1.vue')
  }, 
  {
    path: '/bankInfo',
    name: 'bankInfo',
    component: ()=>import('../views/bankInfo.vue')
  }, 
  {
    path: '/dingdan2',
    name: 'dingdan2',
    component: ()=>import('../views/dingdan2.vue')
  }, 
  {
    path: '/progress',
    name: 'progress',
    component: ()=>import('../views/progress.vue')
  }, 
  {
    path: '/xieyi',
    name: 'xieyi',
    component: ()=>import('../views/xieyi.vue')
  }, 
  {
    path: '/htxieyi',
    name: 'htxieyi',
    component: ()=>import('../views/htxieyi.vue')
  }, 
  {
    path: '/user',
    name: 'user',
    component: ()=>import('../views/user.vue')
  }, 
  {
    path: '/userInfo',
    name: 'userInfo',
    component: ()=>import('../views/userInfo.vue')
  }, 
  {
    path: '/kefu',
    name: 'kefu',
    component: ()=>import('../views/kefu.vue')
  }, 
  {
    path: '/changePd',
    name: 'login',
    component: ()=>import('../views/changePd.vue')
  },
  {
    path: '/set',
    name: 'set',
    component: ()=>import('../views/set.vue')
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: ()=>import('../views/aboutUs.vue')
  },
  {
    path: '/answer',
    name: 'answer',
    component: ()=>import('../views/answer.vue')
  },
  {
    path: '/signature',
    name: 'signature',
    component: ()=>import('../views/signature.vue')
  },
]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
