export default{
	以下是有关我们网站的一些注意事项:'以下是有关我们网站的一些注意事项',
	关于在线:"关于在线 ATM",
	在线分期贷款平台:"ATM online专为年轻人设计的在线分期贷款平台!我们可以随时随地借现金分期和网上分期，贷款期限灵活，可分36期还款，可以通过电话登记，操作流程简单明了，您可以清楚地查看贷款还款进度。减轻您的生活负担，暂时缓解您的财务压力1.贷款限额高: 40,000,000 越南盾起，最高可达 1,000,000,000 越南盾2.月利率低: 月利率低至 0.8% 3.低调要求:有身份证即可贷款，无需抵押 4.超快贷款: 仅需 30 分钟即可到账。",
	'答：首先，您需要在APP上进行实名认证，实名认证后，即可申请贷款。目前，由于疫情情况，只要年满18岁即可申请贷款！':"答：首先，您需要在APP上进行实名认证，实名认证后，即可申请贷款。目前，由于疫情情况，只要年满18岁即可申请贷款！",
	'答：从账户注册、实名认证、贷款审核和最终贷款审核，最快可在一小时内完成。同时，请保持电话保持正常状态，当您等待贷款审核时，工作人员会打电话给您进行核实。':'答：从账户注册、实名认证、贷款审核和最终贷款审核，最快可在一小时内完成。同时，请保持电话保持正常状态，当您等待贷款审核时，工作人员会打电话给您进行核实。',
	'答：您可以选择查看[个人中心]-[贷款申请]中的贷款结果。批准时间通常在工作时间的一小时内完成。':'答：您可以选择查看[个人中心]-[贷款申请]中的贷款结果。批准时间通常在工作时间的一小时内完成。',
	'答：一旦提交贷款申请，一般无法取消，因此在申请前应仔细考虑。如果贷款登记获得批准，合同将生效。即使不再需要贷款，利息也会计算。':'答：一旦提交贷款申请，一般无法取消，因此在申请前应仔细考虑。如果贷款登记获得批准，合同将生效。即使不再需要贷款，利息也会计算。',
	'答：贷款成功后，还款方式只有一种方式。每月 10 日，相应金额将自动从您的银行卡中扣除。请在每月10日之前将银行卡中的余额保持充足。如果余额不足以偿还债务，您的信用信誉将受到影响。信用信誉绑定银行机构，这将影响您购车贷款和房屋抵押贷款的登记，以后的信用卡也会受到影响。':'答：贷款成功后，还款方式只有一种方式。每月 10 日，相应金额将自动从您的银行卡中扣除。请在每月10日之前将银行卡中的余额保持充足。如果余额不足以偿还债务，您的信用信誉将受到影响。信用信誉绑定银行机构，这将影响您购车贷款和房屋抵押贷款的登记，以后的信用卡也会受到影响。',
	'上午9：00至21：00回复：客服中心可在[客户服务]栏目中在线联系。在线客户服务的工作时间为：上午 09：00 至下午 21：00':'上午9：00至21：00回复：客服中心可在[客户服务]栏目中在线联系。在线客户服务的工作时间为：上午 09：00 至下午 21：00',
	'答：暂时没有提供“帐户取消”服务，您填写的个人信息都严格保密，只用于贷款申请。':'答：暂时没有提供“帐户取消”服务，您填写的个人信息都严格保密，只用于贷款申请。',
	旧密码:"旧密码",
	请输入旧密码:"请输入旧密码",
	新密码:"新密码",
	请输入新密码:"新密码",
	确定密码:"确定密码",
	请重新输入新密码:"请重新输入新密码",
	确认:"确认",
	智慧信贷:"智慧信贷",
	轻松贷款:"轻松贷款",
	预计贷款金额:"预计贷款金额",
	越南盾:"越南盾",
	月利率:"月利率",
	'贷款期限（月）':"贷款期限（月）",
	恭喜:"恭喜",
	成功借钱:"成功借钱",
	预计贷款金额:"预计贷款金额",
	韩元:"韩元",
	预计贷款金额:"预计贷款金额",
	现在申请:"现在申请",
	最高贷款额达:"最高贷款额达",
	'10分钟到钱，利率低每月仅0.8%，手续快捷，无需房产抵':'10分钟到钱，利率低每月仅0.8%，手续快捷，无需房产抵',
	登录:"登录",
	登记:"登记",
	请输入您的手机号码:"请输入您的手机号码",
	请输入密码:"请输入密码",
	请输入您的邀请码:"请输入您的邀请码",
	登录:"登录",
	修改登录密码:"修改登录密码",
	关于我们:"关于我们",
	版本:"版本",
	改写:"改写",
	账户余额:"账户余额",
	立即提款:"立即提款",
	我的银行卡:"我的银行卡",
	无需绑定银行卡:"无需绑定银行卡",
	资金账户安全由银行保障:"资金账户安全由银行保障",
	提现状态:"提现状态",
	提现时间:"提现时间",
	提现金额:"提现金额",
	没有:"没有",
	有:"有",
	账户名称:"账户名称",
	请输入持卡人姓名:"请输入持卡人姓名",
	身份证:"身份证",
	请输入身份证号码:"请输入身份证号码",
	存款银行:"存款银行",
	银行名:"银行名",
	银行卡号:"银行卡号",
	请输入银行卡号:"请输入银行卡号",
	发送确认信息:"发送确认信息",
	教育:"教育",
	请选择您的最高学历:"请选择您的最高学历",
	月收入:"月收入",
	个人收入:"个人收入",
	贷款用途:"贷款用途",
	请选择贷款用途:"请选择贷款用途",
	带或不带外壳:"带或不带外壳",
	有车或无车:"有车或无车",
	用户名:"用户名",
	请填写用户名:"请填写用户名",
	身份证号码:"身份证号码",
	请填写身份证号码:"请填写身份证号码",
	身份证照片上传:"身份证照片上传",
	上传身份证正面照片:"上传身份证正面照片",
	上传身份证反面照片:"上传身份证反面照片",
	客服服务:"客服服务",
	您的专属Line客服经理:"您的专属Line客服经理",
	服务时间:"客服服务时间为：每天上午09:00至晚上21:00",
	点击联系:"点击联系",
	手机号码:"手机号码",
	请输入手机号码:"请输入手机号码",
	钱包余额:"钱包余额",
	已验证:"已验证",
	我的贷款:"我的贷款",
	我的债务偿还:"我的债务偿还",
	在线客服服务:"在线客服服务",
	常见问题:"常见问题",
	贷款协议:"贷款协议",
	完全的:"完全的",
	准确性:"准确性",
	为了保护您的资金安全:"为了保护您的资金安全，请如实填写以下信息",
	个人信息:"个人信息",
	信息:"信息",
	银行卡:"银行卡",
	已验证电话:"已验证电话",
	手写签名:"手写签名",
	现在申请:"现在申请",
	点击查看贷款协议:"点击查看贷款协议",
	我已仔细阅读贷款合同:"我已仔细阅读贷款合同，并仔细核对贷款信息。点击“确定”按钮注册付款后，借款合同即生效。如果我因个人原因违约，我愿意承担一切法律后果.",
	月利率:"月利率",
	贷款期限:"贷款期限",
	贷款:"贷款",
	电话号码:"电话号码",
	借款人姓名:"借款人姓名",
	贷款信息确认:"贷款信息确认",
	传单:"传单",
	'您的贷款申请已成功，请前往钱包提现':"您的贷款申请已成功，请前往钱包提现",
	贷款详情:"贷款详情",
	数字顺序:"数字顺序",
	序列号:"序列号",
	贷款进度:"贷款进度",
	'债务偿还期限（月）':"债务偿还期限（月）",
	提交完成:"提交完成",
	账户成功:"账户成功",
	银行账户:"银行账户",
	卡号:"卡号",
	分期付款:"分期付款",
	付款说明:"付款说明",
	审核成功:"审核成功",
	贷款合同代码:"贷款合同代码",
	'贷款期限（月）':'贷款期限（月）',
	申请期间:"申请期间",
	查看合同:"查看合同",
	还债时间:"还债时间",
	偿还债务金额:"偿还债务金额",
	支付:"支付",
	银行:"银行",
	您目前没有任何贷款订单:"您目前没有任何贷款订单",
	//贷款协议
	'1.甲方（贷款人）：':"1.甲方（贷款人）：",
	'公司：':"公司：",
	'税号：':"税号：",
	'代表人：':"代表人：",
	'职位：':"职位：",
	'2.乙方（借款人）：':"2.乙方（借款人）：",
	'全名（先生/女士）：':"全名（先生/女士）：",
	'电话号码：':"电话号码：",
	'ID/CCCD号码：':"ID/CCCD号码：",
	'借款日期：':"借款日期：",
	'贷款币种：':"贷款币种：",
	'贷款金额：':"贷款金额：",
	'贷款利率：':"利率按贷款总额计算：",
	'贷款期限：':"贷款期限：",
	'每月付款：':"每月付款：",
	'每月还款日期：':"每月还款日期： ",
	'双方同意甲方按以下条款从甲方资金中向乙方贷款：':"双方同意甲方按以下条款从甲方资金中向乙方贷款：",
	'第一条：甲方的权利和义务':"第一条：甲方的权利和义务",
	'- 具有甲方《运作规则》规定的权利和义务':"- 具有甲方《运作规则》规定的权利和义务",
	'- 要求乙方履行承诺的义务。':"- 要求乙方履行承诺的义务。",
	'- 发现乙方提供虚假信息、违反合同的，停止贷款、终止贷款、到期前收回债务。':"- 发现乙方提供虚假信息、违反合同的，停止贷款、终止贷款、到期前收回债务。",
	'- 按照本合同约定的时间表向乙方交付贷款。':"- 按照本合同约定的时间表向乙方交付贷款。",
	'- 每月收取贷款利息，如果乙方无法在付款期限内偿还债务，则收取逾期债务利息。':"- 每月收取贷款利息，如果乙方无法在付款期限内偿还债务，则收取逾期债务利息。",
	'第二条：贷款用途':"第二条：贷款用途",
	'- 贷款用途正确，严格遵守贷款合同约定的其他内容':"- 贷款用途正确，严格遵守贷款合同约定的其他内容",
	'- 按时足额偿还甲方的所有债务（本金和利息）':"- 按时足额偿还甲方的所有债务（本金和利息）",
	'- 因未能遵守本合同项下的承诺而承担法律责任。':"- 因未能遵守本合同项下的承诺而承担法律责任。",
	'第三条：贷款利率及违约责任':"第三条：贷款利率及违约责任",
	'- 利息按贷款总额计算，按照贷款利率乘以贷款期限。':"- 利息按贷款总额计算，按照贷款利率乘以贷款期限。",
	'- 贷款期限自乙方收到贷款之日起至乙方全额支付本金和利息（如有逾期利息，包括逾期利息）之日止。':"贷款期限自乙方收到贷款之日起至乙方全额支付本金和利息（如有逾期利息，包括逾期利息）之日止。",
	'- 一旦客户确认贷款并向支付系统提交申请，即表示客户同意合同中规定的全部条款。':"- 一旦客户确认贷款并向支付系统提交申请，即表示客户同意合同中规定的全部条款。",
	'- 贷款合同正式生效，未偿还利率按月分期计算。':"贷款合同正式生效，未偿还利率按月分期计算。",
	'- 如果您不遵守所签订合同的条款，单方面解除合同将承担以下费用：':"如果您不遵守所签订合同的条款，单方面解除合同将承担以下费用：",
	'a.申请费：':"a.申请费：",
	'b.快速申请费：':"b.快速申请费：",
	'c.合同补偿费为贷款价值的：':"c.合同补偿费为贷款价值的",
	'd.提前还款：提前还款时按余额的3%收取提前还款费。':"提前还款：提前还款时按余额的3%收取提前还款费。",
	'第四条：争议的解决':"第四条：争议的解决",
	'- 这些合同纠纷将由双方本着平等互利的精神协商解决。':"- 这些合同纠纷将由双方本着平等互利的精神协商解决。",
	'如果争议不能通过协商解决，双方将向有管辖权的法院提起诉讼解决。':"如果争议不能通过协商解决，双方将向有管辖权的法院提起诉讼解决。",
	'第五条：合同的效力':"第五条：合同的效力",
	'- 合同自签订之日起生效，至义务履行完毕时终止。':"- 合同自签订之日起生效，至义务履行完毕时终止。",
	'越南ATM在线有限公司':"越南ATM在线有限公司",
	'地址：':"地址：",
	'胡志明市平盛区第 25 区奠边府 561A 珍珠广场大厦 8 楼 02 号办公室':"胡志明市平盛区第 25 区奠边府 561A 珍珠广场大厦 8 楼 02 号办公室",
	'签约地址：':"签约地点/日期",
	'胡志明市，2023 年 7 月，22 年':"胡志明市，2023 年 7 月，22 年",
	'（签名、全名和印章）':"（签名、全名和印章）",
	'贷款协议':"贷款协议",
	'合同编号':"合同编号",
	'信贷机构法':"根据《信贷机构法》2016 年 12 月 30 日第 39/2016/TT-NHNN 号通知《信贷机构和外国银行分行对客户的日常活动规定》。",
	'月':'月',
	//add
	注册失败:"注册失败",
	用户已存在:"用户已存在",
	邀请码不存在:"邀请码不存在",
	注册成功:"注册成功",
	用户不存在:"用户不存在",
	密码错误:"密码错误",
	我的资料:"我的资料",
	工作信息:"工作信息",
	验证电话:"验证电话",
	未完成:"未完成",
	已完成:"已完成",
	非法格式:"非法格式",
	无法修改:"无法修改",
	信息不能为空:"信息不能为空",
	成功:"成功",
	取消:"取消",
	装修:"装修",
	购物:"购物",
	其他:"其他",
	是否有贷款:"是否有贷款",
	未分配客服:"未分配客服",
	退出:"退出",
	退出成功:"退出成功",
	登录成功:"登录成功",
	请在我的资料完成个人信息:"请在我的资料完成个人信息",
	请在我的资料填写工作信息:"请在我的资料填写工作信息",
	请在我的资料填写银行卡填写:"请在我的资料填写银行卡填写",
	请在我的资料验证电话:"请在我的资料验证电话",
	请在我的资料验证签名:"请在我的资料验证签名",
	等待审核:"等待审核",
	已有贷款订单:"已有贷款订单",
	审核中:"审核中",
	审核通过:"审核通过",
	拒绝申请:"拒绝申请",
	还款状态:"还款状态",
	未还款:"未还款",
	
	初验通过:"初验通过",
	核验资料:"核验资料",
	审核通过:"审核通过",
	等待下款:"等待下款",
	已下款:"已下款",
	拒绝申请:"拒绝申请",
	提现金额不足:"提现金额不足",
	已有提现订单:"已有提现订单",
	重写:"重写",
	两次密码不一致:"两次密码不一致",
	修改成功:"修改成功",
	
	没有借款申请:"没有借款申请",
	身份证号码错误:"身份证号码错误",
	手机号码不正确:"手机号码不正确",
	提现密码请联系在线客服获取:"提现密码请联系在线客服获取",
	提现密码:"提现密码",
	提现密码错误:"提现密码",
	请重新登录:"请重新登录",
}

	