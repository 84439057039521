<template>
    <div class="display_flex tabBer">
        <div @click="routerGo('/index');" :class="active=='Home'?'active':''">
          <van-icon class="icon" name="wap-home-o" />
        </div>
        <div @click="routerGo('/bank')" :class="active=='bank'?'active':''">
          <van-icon class="icon" name="pending-payment" />
        </div>
        <div @click="routerGo('/kefu')" :class="active=='kefu'?'active':''">
          <van-icon class="icon" name="user-circle-o" />
        </div>
        <div @click="routerGo('/user')" :class="active=='user'?'active':''">
          <van-icon class="icon" name="contact" />
        </div>
    </div>
  </template>
  
  <script>
  
  export default {
    data(){
      return {
        
      }
    },
    props:['active'],
    methods:{
      routerGo(url){
        if(this.$route.path==url)return;
        this.$router.push(url)
      }
    }
  }
  </script>
  <style lang="scss" scoped>
    .tabBer {
      text-align: center;
      justify-content: space-around;
      position: fixed;
      bottom: 0;
      width: 100%;
	  left: 0;
      background-color: #fff;
      padding: 6px 0px;
      font-size: 12px;
      height: 44px;
	  z-index: 2001;
    }
    .icon {
      font-size: 26px;
      display: block;
      margin-bottom: 6px;
	  color: #333;
    }
    .active {
		.icon{
			color: #006ed4 !important;
		}
      
    }
  </style>
  